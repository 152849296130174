/* SLICK SLIDER */

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev,
.slick-next {
  z-index: 1;
  width: 6rem;
  height: 6rem;

  @media screen and (max-width: $screen-sm) {
    display: none;
  }
}

.slick-prev {
  background: rgba($white, .9) url("./images/chevron-left.svg") center no-repeat;
  background-size: 1.5rem 3rem;
  border-radius: 50%;
  box-shadow: $shadow-card-elevado;

  &:hover, &:focus {
    background: rgba($white, .9) url("./images/chevron-left.svg") center no-repeat;
    background-size: 1.5rem 3rem;
  }

  &:hover {
    opacity: .8;
  }
}

.slick-next {
  background: rgba($white, .9) url("./images/chevron-right.svg") center no-repeat;
  background-size: 1.5rem 3rem;
  border-radius: 50%;
  box-shadow: $shadow-card-elevado;

  &:hover, &:focus {
    background: rgba($white, .9) url("./images/chevron-right.svg") center no-repeat;
    background-size: 1.5rem 3rem;
  }

  &:hover {
    opacity: .8;
  }
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-dots {
  bottom: 1.5rem;
}

.slick-dotted.slick-slider {
  margin: 0;
}

.slick-dots li {
  &.slick-active {
    button {
      background-color: $primary;
    }
  }

  button {
    border: none;
    background-color: $preto30;
    border-radius: 20px;

    &::before {
      display: none;
    }
  }
}

/* CONTACT FORM 7 */

.wpcf7-form-control-wrap {
  display: block;
  margin-bottom: 1.5rem;
}

.wpcf7 form .wpcf7-response-output {
  margin: 1.5rem 0 !important;
}

.wpcf7 .ajax-loader {
  position: absolute !important;
}

/* DATEPICKER */
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  border-color: $primary!important;
  background: $primary!important;
}

/* WORDPRESS */

.wp-block-image img {
  height: auto;
}

/* gallery */
.gallery {
  display: flex;
  margin-bottom: 1.5rem!important;

  @media screen and (max-width: $screen-sm) {
    flex-direction: column;
  }

  > .gallery-item {
    float: none;
    padding: 0 1.5rem;

    @media screen and (max-width: $screen-sm) {
      width: 100%!important;
    }
  }

  a {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    &:hover {
      opacity: .8;
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: .5rem;
      border: none!important;
      box-shadow: $shadow-card;
      padding: .5rem;
      background-color: $white;
    }
  }
}