.breadcrumb-app {
  position: relative;
  display: inline-block;
  background-color: $secondary;
  padding: 2.5rem 3rem;
  font-size: 1.4rem;
  line-height: 1.42;
  font-weight: 700;
  border-radius: 6rem;
  outline: 1rem solid rgba($secondary, .3);
  color: $white;

  @media screen and (max-width: $screen-sm) {
    overflow-y: auto;
  }

  ul {
    display: flex;

    > li {
      @media screen and (max-width: $screen-sm) {
        white-space: nowrap;
      }

      a {
        color: rgba($white, .6);
        text-decoration: none;
      }

      &.separator {
        color: rgba($white, .6);
      }
    }
  }
}