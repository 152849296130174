.list-contact {
  > .list {
    > .item {
      display: flex;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      > .link {
        display: flex;
        align-items: center;
        color: $preto100;
        text-decoration: none;

        &:hover {
          color: $primary;
        }

        > i {
          margin-right: .5rem;
          color: $primary;
        }
      }
    }
  }
}