/*!
Theme Name: Hotel Rafain Centro
Theme URI: https://www.rafaincentro.com.br
Author: Rodrigo Cintrão
Description: Tema criado para o Hotel Rafain Centro
Version: 1.0
*/

/* VARIABLES */
@import "vars";
@import "colors";

/* RESET AND NORMALIZE */
@import "../node_modules/css-reset-and-normalize/scss/reset-and-normalize";

/* BOOTSTRAP */
@import "../node_modules/bootstrap/scss/bootstrap-grid";

/* FONTAWESOME */
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";

/* Lightbox */
@import "lightbox";

/* Slick */
@import "slick";
@import "slick-theme";

@import "aboutus-app";
@import "animations";
@import "article-room";
@import "aside-app";
@import "breadcrumb-app";
@import "button-app";
@import "card-app";
@import "card-footer";
@import "contact-app";
@import "contact-aside";
@import "copy-app";
@import "divisor-app";
@import "excerpt-app";
@import "experiences-app";
@import "filterreservation-app";
@import "float-top";
@import "fonts";
@import "footer-app";
@import "form-contact";
@import "gallery-app";
@import "general";
@import "header-app";
@import "headerpage-app";
@import "info-contact";
@import "infocontact-app.scss";
@import "list-contact";
@import "list-links";
@import "list-social";
@import "main-app";
@import "media-app";
@import "override";
@import "page-404";
@import "pre_loader";
@import "progdiscounts-app";
@import "promotions-app";
@import "rooms-app";
@import "slider-app";
@import "svg-app";
@import "the-content";
@import "title-app";

// login wordpress
.login {
  background-color: $mercury;

  h1 a {
    background: url("./images/logo-login.png") transparent no-repeat center /
      contain;
    width: 100%;
    height: 8rem;
  }

  input {
    display: inline-block;
    width: auto;
    height: 4rem;
  }

  input[type="checkbox"],
  input[type="radio"] {
    width: 2rem;
    height: 2rem;
  }

  select {
    display: inline-block;
  }
}
