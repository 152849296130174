.list-links {
  ul {
    > li {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      > a {
        color: $preto100;
        text-decoration: none;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}