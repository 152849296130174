.card-footer {
  font-size: 1.4rem;
  line-height: 1.42;
  color: $white;
  padding: 0 1.5rem;
  margin-bottom: 3rem;

  &.-nomargin {
    margin: 0 !important;
  }

  > .title {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: auto;
    letter-spacing: 0.75px;
    margin-bottom: 1.5rem;
    color: $primary;
    text-transform: uppercase;
  }

  > .list {
    &.-social {
      display: flex;

      > .item {
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }

        > .link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          background-color: $salomie;
          color: #352600;
          border-radius: 50%;
          font-size: 3rem;
          line-height: 1;

          &:hover {
            text-decoration: none;
            opacity: 0.8;
          }
        }
      }
    }

    &.-liststyle {
      > .item {
        > i {
          margin-right: .5rem;
        }

        > .link {
          > i {
            margin-right: .5rem;
          }
        }
      }
    }

    > .item {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      > .link {
        color: $white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      > .image {
        > img {
          width: 80px;
          height: 80px;
          object-fit: cover;
          margin-right: 1.5rem;
        }
      }
    }
  }

  p {
    margin-bottom: 1.42rem;
  }
}
