.card-app {
  position: relative;
  display: block;
  width: 100%;
  background-color: $white;
  border-radius: 1.5rem;
  box-shadow: $shadow-card;
  padding: 1rem;
  margin-bottom: 3rem;
  transition: .6s;

  &.-p3rem {
    padding: 3rem;
  }

  &.-mb1-5rem {
    margin-bottom: 1.5rem;
  }

  &.-nomargin {
    margin: 0;
  }

  &.-footerfixed {
    padding-bottom: 6.4rem;

    .footer {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .text {
    padding: 2rem 2rem 0;
    margin-bottom: 1.5rem;

    &.-p2rem {
      padding: 2rem;
    }
  }

  .footer {
    padding: 0 2rem 2rem;
  }

  > .benefits {
    margin-bottom: 3rem;
  }

  *:last-child {
    margin: 0;
  }

  &.-aboutus {

    .excerpt {
      margin-bottom: 1.5rem;
    }

    > .row {
      margin: 0;

      > .col-sm-6 {
        padding: 0;
      }
    }
  
    .text {
      padding: 0 6rem;
    }

    .-video {
      border-top-right-radius: 1rem;

      @media screen and (max-width: $screen-sm) {
        margin: 1rem 0;
        border-radius: 1rem;
      }
    }

    .-image {
      border-bottom-left-radius: 1rem;

      @media screen and (max-width: $screen-sm) {
        margin-bottom: 1rem;
        border-radius: 1rem;
      }
    }
  }

  &.-promotions {
    li {
      display: flex;
      margin-bottom: 1rem;

      &::before {
        display: block;
        content: url('images/check.svg');
        margin-right: 1rem;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.-experiences {
    > a {
      > .media-app {
        > img {
          transition: .6s;
        }
      }
    }

    &:hover {
      box-shadow: $shadow-card-elevado;

      > a {
        > .media-app {
          > img {
            transform: scale(1.1);
          }
        }
      }
    }

    > .floatbox{
      position: absolute;
      left: 0;
      bottom: 0;
      text-align: center;
      padding: 2rem;

      > .content {
        padding: 3rem;
        background-color: $white;
        border-radius: .5rem;
        box-shadow: $shadow-card-elevado;

        > .title-app {
          margin: 0;
        }

        > .toggle {
          height: auto;
          max-height: 0;
          overflow: hidden;
          transition: .8s ease-in-out;

          > .excerpt {
            margin: 1.5rem 0;
          }

          > .footer {
            padding: 0;
          }
        }
      }
    }

    &:hover {
      > .floatbox{
  
        > .content {
  
          > .toggle {
            max-height: 600px;
          }
        }
      }
    }
  }
}