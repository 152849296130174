.filterreservation-app {
  background-color: $white;
  padding: 1rem 1.5rem;
  border-radius: 5rem;

  @media screen and (max-width: $screen-sm) {
    border-radius: 1rem;
  }

  > .form {
    display: flex;
    justify-content: center;

    @media screen and (max-width: $screen-sm) {
      flex-direction: column;
    }

    &.-pages {
      display: block;

      input, select {
        margin-bottom: 1.5rem;
      }

      .adults {
        > .field {
          width: 100%;
        }
      }

      .submit {
        > .button-app {
          width: 100%;

          &::after {
            content: "Reservar";
          }
        }
      }

      .checkin, .checkout, .coupon, .adults, .submit {
        display: block;
        margin-right: 0; 

        > label {
          display: block;
          margin-bottom: .5rem;
          margin-right: 0;
        }
      }
    }

    input, select {
      background-color: $preto10;
    }

    .adults, .children {
      > .field {
        width: 8rem;

        @media screen and (max-width: $screen-sm) {
          width: 100%;
        }
      } 
    }

    .submit {
      > .button-app {
        padding: 1.8rem;

        @media screen and (max-width: $screen-sm) {
          width: 100%;

          &::after {
            content: "Reservar";
          }
        }
      }
    }

    .checkin, .checkout, .coupon, .adults, .submit {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.42;
      margin-right: 1.5rem;

      @media screen and(max-width: $screen-lg) {
        flex-direction: column;
      }

      @media screen and (max-width: $screen-sm) {
        margin-right: 0;
        align-items: flex-start;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      > label {
        color: $preto100;
        margin-right: .5rem;
      }

      > .button {
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        border: none;
        color: $preto100;
        cursor: pointer;

        @media screen and (max-width: $screen-lg) {
          transform: none;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}