.infocontact-app {
  > .list {
    margin: 0 !important;

    > .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid $preto10;
      padding: 1.5rem 0;

      &:last-child {
        border-bottom: none;
      }

      &::before {
        content: none;
      }

      > i {
        font-size: 4.5rem;
        line-height: 1;
        margin-bottom: .5rem;
      }

      > .title {
        font-weight: 700;
        margin-bottom: .5rem;
      }
    }
  }

  &.-grid {
    > .list {
      > .item {
        margin-bottom: 0;
        border-bottom: none;
        border-right: 1px solid $preto10;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}