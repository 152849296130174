.header-app {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 8rem;
  background-color: $white;
  z-index: 100;
  box-shadow: 0 4px 8px rgba($black, 0.2);
  border-radius: 0 0 15px 15px;

  > .container {
    display: flex;

    > .content {
      display: flex;
      width: 100%;
      justify-content: space-between;

      > .logo {
        display: flex;
        align-items: center;

        > .link {
          > .image {
            display: block;
            height: 4rem;
          }
  
          &.desktop {
            @media screen and(max-width: $screen-md) {
              display: none;
            }
          }
  
          &.mobile {
            display: none;
  
            @media screen and(max-width: $screen-md) {
              display: block;
            }
          }
        }
      }
  
      > .nav {
        display: flex;
        align-items: center;

        > .bars {
          font-size: 20px;
          color: $preto100;
          cursor: pointer;
        }
  
        > .menu {
          display: flex;

          @media screen and (max-width: $screen-md) {
            display: block;
            position: fixed;
            width: 80%;
            height: 100vh;
            top: 0;
            right: 0;
            background-color: rgba($primary, 0.95);
            box-shadow: 0 8px 16px rgba($black, 0.4);
            transform: translate3d(110%, 0, 0);
            transition: 0.3s;
            overflow-y: scroll;
            z-index: 10;
            padding: 0 3rem;
  
            &.active {
              transform: translate3d(0, 0, 0);
            }
          }

          > .button-app {
            align-self: center;

            @media screen and (max-width: $screen-md) {
              background-color: $white;
              color: $primary;
              margin-top: 1.5rem;
            }
          }
  
          > .close {
            color: $white;
            font-size: 20px;
            padding: 15px;
            z-index: 10;
            text-align: right;
            cursor: pointer;
          }
  
          ul {
            display: flex;
  
            @media screen and (max-width: $screen-md) {
              flex-direction: column;
            }
  
            > li {
              display: flex;

              @media screen and (max-width: $screen-md) {
                display: block;
              }

              > a {
                position: relative;
                display: flex;
                align-items: center;
                text-decoration: none;
                font-size: 1.4rem;
                line-height: 1.42;
                color: $preto100;
                padding: 0 1rem;
                transition: 0.3s;

                @media screen and (max-width: $screen-md) {
                  display: block;
                  color: $white;
                  padding: 2rem;
                }
  
                &::after {
                  display: none;
                  content: "";
                  position: absolute;
                  bottom: 0;
                  left: 1rem;
                  height: 5px;
                  width: calc(100% - 2rem);
                  z-index: 1;
                  background-color: $secondary;
                }
  
                &:hover {
                  color: $primary;

                  @media screen and (max-width: $screen-md) {
                    color: $secondary;
                  }
  
                  &::after {
                    display: block;
                  }
                }
              }
  
              &.current-menu-item,
              &.current-page-ancestor {
                > a {
                  color: $primary;
                  font-weight: 600;

                  @media screen and (max-width: $screen-md) {
                    color: $secondary;
                  }

                  &::after {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}
