.list-social {
  > .list {
    display: flex;

    > .item {
      margin-right: 3rem;

      > .link {
        font-size: 3rem;
        line-height: 1.625;
        color: $primary;

        &:hover {
          color: $primary3;
        }
      }
    }
  }

  &.-colorwhite {
    > .list {
      > .item {
        > .link {
          color: $white;
  
          &:hover {
            opacity: .9;
          }
        }
      }
    }
  }
}
