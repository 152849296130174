.media-app {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  &.-vertical {
    padding-bottom: 160%;
  }

  &.-ovisible {
    overflow: visible;
  }

  > iframe, > img, > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > .tag {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: $white;
    background-color: $secondary;
    border-radius: 1rem;
    box-shadow: $shadow-card-elevado;
    padding: 1rem;
    text-align: center;
  }
}