.contact-aside {
  > .infobox {
    margin-bottom: 3rem;
    
    > .title-app {
      display: flex;
      flex-direction: row;
      align-items: center;

      > img {
        margin-right: 1rem;
      }
    }
  }
}