.footer-app {
  position: relative;
  padding: 3rem 0 0;
  background-color: rgba($primary, .90);

  .logo {
    display: block;
    width: 15rem;
    margin: 0 auto;
  }

  .title-app {
    color: $secondary;
  }
}
