.headerpage-app {
  position: relative;
  padding: 8rem 0 0;
  background: url('images/bg-paginas.png') no-repeat center / cover;
  color: $white;
  text-shadow: $shadow-card;

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);
  }

  > .container {
    position: relative;
    padding: 6rem 0;
    z-index: 1;
  }
}