$white: #ffffff;
$black: #000000;

$preto100: #464646;
$preto50: #A3A3A3;
$preto30: #C7C7C7;
$preto10: #ECECEC;
$preto05: #F6F6F6;

$primary: #5B5B58;
$primary1: #BDBDB6;
$primary2: #8B8B87;
$primary3: #1F1F1E;
$primary4: #14140E;

$secondary: #F0C94A;

$mineShaft: #333333;
$mercury: #e5e5e5;
$gray: #808080;

$goldenGrass: #d6a626;
$salomie: #ffdc7f;
$butteredRum: #ab8112;
$brown: #846000;

$success: #6FCF97;
