.main-app {
  flex: 1;

  &.-room, &.-promotion, &.-experience {
    ul {
      margin-bottom: 1.5rem;
      
      li {
        display: flex;
        margin-bottom: 1rem;
  
        &::before {
          display: block;
          content: url('images/check.svg');
          margin-right: 1rem;
        }
  
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    
  }

  &.-room {
    .filterreservation-app {
      box-shadow: $shadow-card;
      margin-bottom: 3rem;
    }
  }

  &.-page404 {
    padding-top: 8rem;
  }

  .rowgallery {
    border-radius: 1rem;
    overflow: hidden;
    margin-bottom: 3rem;

    a {
      transition: .3s;
      
      &:hover {
        opacity: .8;
      }
    }

    > .col-sm-4 {
      padding: 0;
    }
  }
}